import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { DynamicHeader } from "scmp-app/components/app-bar/dynamics";
import { BaseLink } from "scmp-app/components/common/base-link";
import { DynamicHeaderOnelineMenuContainer } from "scmp-app/components/header/dynamics";
import { MegaMenuWrapper } from "scmp-app/components/header/header-oneline-menu-container/header-all-menu/styles";
import IconArrowNextMobile from "scmp-app/components/header/header-oneline-menu-container/header-oneline-menu/icon-arrow-next-mobile.svg?url";
import IconArrowNext from "scmp-app/components/header/header-oneline-menu-container/header-oneline-menu/icon-arrow-next.svg?url";
import IconArrowPreviousMobile from "scmp-app/components/header/header-oneline-menu-container/header-oneline-menu/icon-arrow-previous-mobile.svg?url";
import IconArrowPrevious from "scmp-app/components/header/header-oneline-menu-container/header-oneline-menu/icon-arrow-previous.svg?url";
import {
  StyledSwiper,
  StyledSwiperSlide,
} from "scmp-app/components/header/header-oneline-menu-container/header-oneline-menu/styles";
import {
  AllMenuContainer,
  Divider,
} from "scmp-app/components/header/header-oneline-menu-container/styles";
import IconSearch from "scmp-app/components/home/header/header-icon/search.svg";
import { setFullViewportWidthStyles } from "scmp-app/lib/styles";
import type { ResponsiveVariants } from "scmp-app/lib/styles/responsive";
import { setResponsiveVariantStyles } from "scmp-app/lib/styles/responsive";

import Logo from "./icon-logo.svg";

export type ActionButtonProps = {
  $responsiveVariants: ResponsiveVariants<"show" | "hidden">;
};

export const Container = styled.div`
  ${setFullViewportWidthStyles()}
`;

export const HomeHeaderContainer = styled.section``;

export const ActionButton = styled.div<ActionButtonProps>`
  line-height: 0;

  cursor: pointer;

  ${props =>
    setResponsiveVariantStyles(props.$responsiveVariants, variant => {
      switch (variant) {
        case "show":
          return css`
            display: block;
          `;
        case "hidden":
          return css`
            display: none;
          `;
      }
    })}
`;

export const AvatarContainer = styled.div``;

export const StyledLogo = styled(Logo)`
  inline-size: 207px;
  block-size: 22px;
  ${props => props.theme.breakpoints.up("tablet")} {
    inline-size: 330px;
    block-size: 34.3px;
  }

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    inline-size: 481px;
    block-size: 50px;
  }
`;

export const StyledLogoLink = styled(BaseLink)`
  position: absolute;
  inset-inline-start: 50%;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  transform: translateX(-50%);

  ${props => props.theme.breakpoints.up("tablet")} {
    align-items: flex-start;
  }
`;

export const StyleSearchLink = styled(BaseLink)`
  line-height: 0;
`;

export const StyledIconSearch = styled(IconSearch)`
  inline-size: 24px;
  block-size: 24px;
`;

export const HeaderContainer = styled.div`
  position: relative;

  background-color: transparent;

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    border-block-end: 1px solid #000000;
  }
`;

export const Wrapper = styled.div`
  position: relative;

  max-inline-size: 1344px;
  margin-inline: auto;

  ${props => props.theme.breakpoints.up("tablet")} {
    padding-block: 16px 0;
    padding-inline: 0;
  }

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    padding-block: 24px 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;

  inline-size: 100%;
  block-size: 45px;
  padding-inline: 20px;
  box-sizing: border-box;

  ${props => props.theme.breakpoints.up("tablet")} {
    block-size: 60px;
    padding-inline: 32px;
  }

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    block-size: 64px;
    margin-block-end: 24px;
  }
`;

export const LeftFeatureContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;

  & > *:not(:last-child) {
    margin-inline-end: 20px;
  }
`;

export const RightFeatureContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;

  ${props => props.theme.breakpoints.up("tablet")} {
    ${ActionButton}:not(:first-child) {
      margin-inline-start: 20px;
    }
  }
`;

export const OnelineMenuContainer = styled.div`
  display: flex;
  justify-content: center;

  block-size: 34px;
  margin-inline: 20px;
  border-block-end: 1px solid #000000;

  ${props => props.theme.breakpoints.between("tablet", "homeDesktop")} {
    margin-inline: 32px;
  }

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    margin-inline: 0;
    border-block-end: none;
  }
`;

export const StyledDynamicHeaderOnelineMenuContainer = styled(DynamicHeaderOnelineMenuContainer)`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-inline-start: 0;
  padding-block: 10px;

  ${AllMenuContainer} {
    display: none;

    ${props => props.theme.breakpoints.up("homeDesktop")} {
      display: flex;
    }
  }

  ${StyledSwiperSlide} {
    &:not(:last-child) {
      margin-inline-end: 16px;

      ${props => props.theme.breakpoints.up("homeDesktop")} {
        margin-inline-end: 14px;
      }
    }
  }

  ${MegaMenuWrapper} {
    margin-block-start: 11px;
  }

  ${StyledSwiper} {
    .swiper-wrapper {
      block-size: auto;
    }

    .swiper-button-prev {
      margin-inline-start: -9px;

      background-image: url(${IconArrowPreviousMobile});

      ${props => props.theme.breakpoints.up("homeDesktop")} {
        background-image: url(${IconArrowPrevious});
      }
    }

    .swiper-button-next {
      margin-inline-end: -9px;

      background-image: url(${IconArrowNextMobile});

      ${props => props.theme.breakpoints.up("homeDesktop")} {
        background-image: url(${IconArrowNext});
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      inline-size: 36px;
      block-size: 17px;

      border-radius: 0;

      background-color: transparent;
      background-size: unset;

      opacity: 1;
    }
  }

  ${Divider} {
    margin-inline: 14px;
  }

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    padding-block: 8px 10px;
  }
`;

type StyledHomeMiniHeaderProps = {
  $shouldSwap: boolean | null;
};

export const StyledHomeMiniHeader = styled(DynamicHeader)<StyledHomeMiniHeaderProps>`
  position: fixed;
  inset-block-start: -60px;

  background-color: #ffffff;

  ${props =>
    props.$shouldSwap === true
      ? css`
          inset-block-start: 198px;

          transition: inset-block-start 0.4s;

          ${props.theme.breakpoints.up("tablet")} {
            inset-block-start: 259px;
          }

          ${props.theme.breakpoints.up("homeDesktop")} {
            inset-block-start: 466px;
          }
        `
      : null}
`;
